import { useCallback, useEffect, useState } from "react";
import { isEmpty, isNull } from "lodash";
import {
	Box,
	Text,
	Textarea,
	VStack,
	HStack,
	Icon,
	Button,
	Badge,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverHeader,
	PopoverBody,
	PopoverCloseButton,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Square,
	SimpleGrid,
	Grid,
	Image,
	SlideFade,
	Input,
	Center,
	FormErrorMessage,
	GridItem,
	useBreakpointValue,
	Stack,
	Spacer,
	Spinner,
	useToast
} from "@chakra-ui/react";
import { BsInfoCircle, BsPlusCircle, BsThreeDotsVertical, BsTrash, BsPencil, BsExclamationTriangle, BsPeople, BsExclamationCircle, BsLifePreserver, BsWrench, BsPaperclip, BsFilePdf, BsFileWord } from "react-icons/bs";
import { IoSkullOutline } from "react-icons/io5";

import { addHazardAttachment, createAction, createControl, deleteHazard, deleteHazardAttachment, getHazard, getHazardAttachment, updateHazard } from "../../features/riskAssessments/riskAssessments.actions";

import { Action } from "./Action";
import { Control } from "./Control";
import { TeamMessageCombobox } from "../messages/TeamMessageCombobox";

import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import obbiHelper from "../../assets/logos/obbi_helper.svg";
import { DropZone } from "../common/DropZone";
import { fileTypeFromBlob } from "file-type";
import { Document, Page, pdfjs } from "react-pdf";

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const Hazard: React.FC<any> = ({
	riskAssessmentId,
	riskAssessmentVersionId,
	teams,
	hazards,
	setHazards,
	hazard,
	getBgColor,
	getHoverColor
}) => {
	//#region State Variables
	const isMobile = useBreakpointValue({ base: true, md: false });
	const toast = useToast();

	const [loading, setLoading] = useState(true);
	const [deleteHazardModalIsOpen, setDeleteHazardModalIsOpen] = useState(false);

	const [hazardData, setHazardData] = useState<any>({
		rahid: "",
		rahdescription: "",
		rahriskcategory: "",
		rahrisklevel: "",
		rahpeopleaffected: "None",
		rahconsequences: "None",
		attachments: [],
	});

	const [editingHazard, setEditingHazard] = useState<any>({
		description: "",
		consequences: "",
		peopleaffected: "",
		risk: { riskText: "", score: 0 },
		riskcontrols: "",
		newcontrolsrequired: "",
		newrisklevel: ""
	});

	const [controls, setControls] = useState<any>([]);
	const [actions, setActions] = useState<any>([]);

	const [resetControlInput, setResetControlInput] = useState<boolean>(false);

	const [isControlModalOpen, setIsControlModalOpen] = useState(false);
	const [newControl, setNewControl] = useState("");

	const [isActionModalOpen, setIsActionModalOpen] = useState(false);
	const [newAction, setNewAction] = useState<any>({
		description: "",
		team: null,
		priority: "",
		status: "",
		category: "",
		duedate: ""
	});

	const [controlError, setControlError] = useState(false);
	const [followUpActionError, setFollowUpActionError] = useState(false);
	const [assignToTeamError, setAssignToTeamError] = useState(false);
	const [dateError, setDateError] = useState(false);

	const [isEditHazardModalIsOpen, setIsEditHazardModalIsOpen] = useState(false);

	const [editingHazardDescriptionError, setEditingHazardDescriptionError] = useState(false);
	const [editingHazardAffectedError, setEditingHazardAffectedError] = useState(false);
	const [editingHazardConsequencesError, setEditingHazardConsequencesError] = useState(false);
	const [editingHazardRiskError, setEditingHazardRiskError] = useState(false);

	const [currentImage, setCurrentImage] = useState("")
	const [isAttachmentOpen, setIsAttachmentOpen] = useState(false)

	const [hazardAttachments, setHazardAttachments] = useState<any[]>([]);
	const [fileUploadLoadingHazardAttachment, setFileUploadLoadingHazardAttachment] = useState(false);
	const [deleteHazardAttachmentIsOpen, setDeleteHazardAttachmentIsOpen] = useState({ hazardId: "", attachmentPath: "", fileName: "", open: false });

	const [currentCert, setCurrentCert] = useState({
		id: 0,
		isPdf: false,
		uri: "",
		fileName: ""
	});
	const [pdfIsOpen, setPdfIsOpen] = useState(false);
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const [addAttachmentModalIsOpen, setAddAttachmentModalIsOpen] = useState(false);

	//#endregion


	//#region Hazard
	const renderDeleteHazardModal = () => {
		return <Modal
			size="lg"
			isOpen={deleteHazardModalIsOpen}
			onClose={() => { setDeleteHazardModalIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Delete Hazard
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<Text
						fontWeight={500}
						fontSize="sm"
						color="gray.600"
					>
						Are you sure you want to delete <b>{hazard?.rahdescription}</b>?
					</Text>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							onClick={() => { setDeleteHazardModalIsOpen(false); }}
							cursor="pointer"
						>
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="red"
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => { deleteHazardFunc(hazard?.rahid); }}
						>
							Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderDeleteHazardAttachmentModal = () => {
		return <Modal
			size="lg"
			isOpen={deleteHazardAttachmentIsOpen?.open}
			onClose={() => { setDeleteHazardAttachmentIsOpen({ hazardId: "", attachmentPath: "", fileName: "", open: false }) }}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<HStack>
						<Text fontWeight={700}>Delete Attachment</Text>
					</HStack>
					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>
					<Text fontWeight={500} color="gray.600" fontSize="sm">
						Are you sure you want to delete this attachment?
					</Text>
				</ModalBody>

				<ModalFooter p={5}>
					<HStack>
						<Button
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								setDeleteHazardAttachmentIsOpen({ hazardId: "", attachmentPath: "", fileName: "", open: false })
							}}
						>
							Cancel
						</Button>

						<Button
							bg="danger.500"
							color="white"
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								deleteAttachmentForHazard(deleteHazardAttachmentIsOpen?.hazardId, deleteHazardAttachmentIsOpen?.attachmentPath, deleteHazardAttachmentIsOpen?.fileName);
								setDeleteHazardAttachmentIsOpen({ hazardId: "", attachmentPath: "", fileName: "", open: false })
							}}
						>
							<Icon as={BsTrash} mr={2} />
							Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetDocViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent mt={0}>
				<ModalHeader
					style={{
						height: "50px",
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentCert.fileName}</Text>
						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>
						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>
						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={currentCert.uri}
						onLoadSuccess={onDocumentLoadSuccess}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
					>
						<Page
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	};

	const resetDocViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const deleteAttachmentForHazard = (hazard: string, attachmentGuid: string, fileName: string) => {

		if (isEmpty(attachmentGuid)) {

			let updatedAttachments = hazardAttachments;
			updatedAttachments = updatedAttachments.filter(attachment => attachment.fileName !== fileName);

			setHazardAttachments(updatedAttachments);
			return;
		}

		deleteHazardAttachment(riskAssessmentId, riskAssessmentVersionId, hazard, attachmentGuid)
			.then((res) => {
				if (!res.success) {
					toast({
						title: res.message,
						description: "",
						status: "error",
						duration: 6000,
						isClosable: true
					});
				} else {
					getHazard(
						riskAssessmentId,
						riskAssessmentVersionId,
						hazard
					)
						.then((res: any) => {

							setHazardData(res.hazard);
							setControls(res.hazard.controls || []);
							setActions(res.hazard.actions || []);

							if (res.hazard?.attachments) {

								let attachments: any = [];

								res.hazard.attachments.forEach((a: any) => {
									const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/hazardAttachment?id=${riskAssessmentId}&version=${riskAssessmentVersionId}&hazard=${a.rahahazard}&attachment=${a.rahapath}&datauri=false&thumbnail=true`;
									const attachment = {
										"thumbnail": thumbnail,
										"fileName": a.rahafilename,
										"path": a.rahapath,
										"isPdf": a.ispdf,
										"isDoc": a.isdoc,
										"hazardId": a.rahahazard,
										"versionId": a.rahaversion
									};

									attachments.push(attachment);
								});

								const newAttachments = hazardAttachments.filter((a: any) => a.path !== attachmentGuid && a.path === undefined);
								attachments = [...attachments, ...newAttachments];

								setHazardAttachments(attachments)
							}
						})
						.catch((error: any) => {
							console.log(error);
						})
						.finally(() => {
							setLoading(false);
						});
				}
			})
			.catch((error: any) => {
				toast({
					title: error.message,
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			})
			.finally(() => setDeleteHazardAttachmentIsOpen({ hazardId: "", attachmentPath: "", fileName: "", open: false }));

	};

	const renderEditHazardModal = () => {
		return <Modal
			size="3xl"
			isOpen={isEditHazardModalIsOpen}
			onClose={() => {
				setEditingHazard({
					description: "",
					consequences: "",
					peopleaffected: "",
					risk: { riskText: "", score: 0 },
					riskcontrols: "",
					newcontrolsrequired: "",
					newrisklevel: ""
				});

				setEditingHazardDescriptionError(false);
				setEditingHazardAffectedError(false);
				setEditingHazardConsequencesError(false);
				setEditingHazardRiskError(false);

				setHazardAttachments(hazardAttachments.filter((a: any) => a.path !== undefined));

				setIsEditHazardModalIsOpen(false);
			}}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					Edit Hazard
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<VStack
						alignItems="start"
						gap={2}
						w="full"
					>
						<FormControl isRequired isInvalid={editingHazardDescriptionError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Hazard Description
							</FormLabel>
							<Input
								defaultValue={hazardData?.rahdescription || ""}
								onChange={(e) => {
									setEditingHazard({
										...editingHazard,
										description: e.target.value
									});
								}} />
						</FormControl>
						<FormControl isRequired isInvalid={editingHazardAffectedError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								People Affected
							</FormLabel>
							<Textarea
								defaultValue={hazardData.rahpeopleaffected}
								rows={3}
								onChange={(e) => {
									setEditingHazard({
										...editingHazard,
										affects: e.target.value
									});
								}}
							/>
							{editingHazardAffectedError && <FormErrorMessage>Please provide parties or individuals at risk</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={editingHazardConsequencesError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Consequences of Hazard
							</FormLabel>

							<Textarea
								defaultValue={hazardData.rahconsequences}
								rows={3}
								onChange={(e) => {
									setEditingHazard({
										...editingHazard,
										consequences: e.target.value
									});
								}}
							/>
							{editingHazardConsequencesError && <FormErrorMessage>Please provide hazard consequences</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={editingHazardRiskError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Select Risk Level
								<Popover>
									<PopoverTrigger>
										<Button variant="link" size="xs"><Icon as={BsInfoCircle} /></Button>
									</PopoverTrigger>
									<PopoverContent>
										<PopoverArrow />
										<PopoverCloseButton />
										<PopoverHeader
											fontWeight={600}
											fontSize="sm"
											color="gray.600"
										>
											RISK SCALE
										</PopoverHeader>
										<PopoverBody>
											<VStack alignItems="start" gap={2} my={2}>
												<Box>
													<Badge colorScheme="gray" mb={1}>
														1 Trivial
													</Badge>
													<Text whiteSpace="pre-wrap">Won’t cause serious injuries or illnesses.</Text>
												</Box>

												<Box>
													<Badge colorScheme="gray" mb={1}>
														2 Minor
													</Badge>
													<Text whiteSpace="pre-wrap">Can cause injuries or illnesses, only to a mild extent.</Text>
												</Box>

												<Box>
													<Badge colorScheme="yellow" mb={1}>
														3 Moderate
													</Badge>
													<Text whiteSpace="pre-wrap">Can cause injuries or illnesses that may require medical attention but limited treatment.</Text>
												</Box>

												<Box>
													<Badge colorScheme="orange" mb={1}>
														4 Serious
													</Badge>
													<Text whiteSpace="pre-wrap">Can cause irreversible injuries or illnesses that require constant medical attention.</Text>
												</Box>

												<Box>
													<Badge colorScheme="red" mb={1}>
														5 Fatal
													</Badge>
													<Text whiteSpace="pre-wrap">Can result in fatality.</Text>
												</Box>
											</VStack>
										</PopoverBody>
									</PopoverContent>
								</Popover>
							</FormLabel>

							<Popover>
								<PopoverTrigger>
									<Button
										variant="link"
										size="xs"
										border="1px solid"
										borderColor={getColor(editingHazard.risk.score || hazardData?.rahrisklevel)}
										onClick={() => { }}
									>
										<Square
											float="right"
											cursor="pointer"
											rounded="md"
											roundedRight="none"
											boxShadow="sm"
											size="40px"
											bg={getColor(editingHazard.risk.score || hazardData?.rahrisklevel)}
										>
											{
												(editingHazard.risk.riskText.indexOf("Fatal") > -1 ||
													hazardData?.rahriskcategory.indexOf("Fatal") > -1) &&
												<Icon
													as={IoSkullOutline}
													color="white"
													mr="2px"
												/>
											}
											<Text
												color="white"
												fontWeight={700}
												fontSize={
													(editingHazard.risk.riskText.indexOf("Fatal") > -1 ||
														hazardData?.rahriskcategory.indexOf("Fatal") > -1) ?
														"10pt" : "lg"
												}
											>
												{editingHazard.risk.score || hazardData?.rahrisklevel}
											</Text>
										</Square>

										<Text
											color={getColor(editingHazard.risk.score || hazardData?.rahrisklevel)}
											fontSize="10pt"
											fontWeight={500}
											mx={3}
										>
											{editingHazard.risk.riskText || hazardData?.rahriskcategory}
										</Text>
									</Button>
								</PopoverTrigger>

								<PopoverContent minW={{ base: "100%", lg: "max-content" }}>
									<PopoverBody py={5} pl={2} pr={5}>
										{renderRiskMatrix()}
									</PopoverBody>
								</PopoverContent>
							</Popover>

							{editingHazardRiskError && <FormErrorMessage>Please select a risk</FormErrorMessage>}
						</FormControl>
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							cursor="pointer"
							size="sm"
							onClick={() => {
								setEditingHazard({
									description: "",
									consequences: "",
									peopleaffected: "",
									risk: { riskText: "", score: 0 },
									riskcontrols: "",
									newcontrolsrequired: "",
									newrisklevel: ""
								});

								setEditingHazardDescriptionError(false);
								setEditingHazardAffectedError(false);
								setEditingHazardConsequencesError(false);
								setEditingHazardRiskError(false);

								setIsEditHazardModalIsOpen(false);
							}}
						>
							Cancel
						</Button>

						<Button
							colorScheme="brand"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => {
								validateEditHazardForm();
							}}
						>
							Update
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const onDropActionHazardAttachment = async (file: any) => {

		const fileExtension = await fileTypeFromBlob(file);

		const supportedFileTypes = ["pdf", "docx", "png", "jpg", "jpeg"];

        if (!supportedFileTypes.includes(fileExtension?.ext ?? "")) {
            toast({
                title: "Unsupported attachment type",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });

            return;
        }

		const previewUrl = URL.createObjectURL(file);

		setHazardAttachments(hazardAttachments => [...hazardAttachments, {
			thumbnail: previewUrl,
			isPdf: fileExtension?.ext === "pdf",
			isDoc: fileExtension?.ext === "docx",
			fileName: file.name,
			file: file,
		}]);
	};

	const renderAddHazardAttachmentModal = () => {
		return <Modal
			size="lg"
			isOpen={addAttachmentModalIsOpen}
			onClose={() => {

				//remove unsaved attachments from the list
				if (hazardAttachments.length > 0 && hazardAttachments.some((a: any) => a.path === undefined)) {
					const updatedAttachments = hazardAttachments.filter((a: any) => a.path !== undefined);
					setHazardAttachments(updatedAttachments);
				}

				setAddAttachmentModalIsOpen(false);
			}}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					{hazardAttachments && hazardAttachments?.length > 0 && hazardAttachments?.some((a) => a.path != undefined) ? "Update Attachments" : hazardAttachments?.filter((a) => a.path === undefined)?.length > 1 ? "Add Attachments" : "Add Attachment"}
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<DropZone
						onDropAction={onDropActionHazardAttachment}
						fileUploadLoading={fileUploadLoadingHazardAttachment}
						additionalAcceptedFileTypes={['DOCX']}
					/>
					{
						hazardAttachments.length > 0 &&
						<VStack alignItems="start" mt={5}>
							<FormControl>
								<SlideFade in={!loading}
									offsetY="20px"
								>
									<FormLabel
										fontWeight={500}
										fontSize="sm"
										color="gray.500"
									>
										<HStack>
											<Icon as={BsPaperclip} fontSize="md" />
											<Text>Attachments</Text>
										</HStack>
									</FormLabel>
									<Box
										display={'flex'}
										flexDirection={'row'}
										flexWrap={'wrap'}
										justifyContent={'flex-start'}
										alignItems={'center'}
										gap={3}
									>
										{
											hazardAttachments.map((a: any, index: any) => {
												return (
													<>
														{
															(a.isPdf || a.isDoc) ?
																<>
																	{
																		a.path ?
																			<Box minW="100px" key={index}>
																				<Image
																					className="hover-pop"
																					cursor="pointer"
																					src={a.thumbnail}
																					fallbackSrc='https://via.placeholder.com/160'
																					alt={a.fileName}
																					boxSize='100px'
																					boxShadow="xs"
																					objectFit="cover"
																					border="1px solid"
																					borderColor="gray.200"
																					rounded="lg"
																					onClick={() => {
																						getHazardAttachment(riskAssessmentId, riskAssessmentVersionId, a.hazardId, a.path, (a.isPdf || a.isDoc))
																							.then((res: any) => {
																								setCurrentCert({
																									id: Number(a.hazardId),
																									isPdf: a.isPdf,
																									fileName: a.fileName,
																									uri: res.datauri
																								});
																								setPdfIsOpen(true);
																							}
																							);
																					}}
																				/>

																				<Button
																					className="hover-pop"
																					variant="link"
																					size="xs"
																					color="gray.500"
																					_hover={{ color: "" }}
																					onClick={() => {
																						setDeleteHazardAttachmentIsOpen({ hazardId: hazardData.rahid, attachmentPath: a.path, fileName: a.fileName, open: true });
																					}}
																				>
																					<Text textDecoration="underline">Delete</Text>
																				</Button>
																			</Box>
																			:
																			<Box minW="100px" key={index}>
																				<Box minW="100px"
																					boxSize='100px'
																					boxShadow="xs"
																					border="1px solid"
																					borderColor="gray.200"
																					rounded="lg"
																					display={'flex'}
																					flexDirection={'column'}
																					justifyContent={"center"}
																					alignItems={"center"}
																					p={1}
																				>
																					<Icon as={a.isPdf ? BsFilePdf : BsFileWord} style={{ fontSize: "30px" }} />
																					<Text
																						fontSize={'xx-small'}
																					>{a.fileName}</Text>
																				</Box>
																				<Button
																					className="hover-pop"
																					variant="link"
																					size="xs"
																					color="gray.500"
																					_hover={{ color: "" }}
																					onClick={() => {
																						setDeleteHazardAttachmentIsOpen({ hazardId: hazardData.rahid, attachmentPath: a.path, fileName: a.fileName, open: true });
																					}}
																				>
																					<Text textDecoration="underline">Delete</Text>
																				</Button>
																			</Box>
																	}</>
																:
																<Box minW="100px">
																	<Image
																		className="hover-pop"
																		cursor="pointer"
																		src={a.thumbnail}
																		fallbackSrc='https://via.placeholder.com/160'
																		alt={a.fileName}
																		boxSize='100px'
																		boxShadow="xs"
																		objectFit="cover"
																		border="1px solid"
																		borderColor="gray.200"
																		rounded="lg"
																		onClick={() => {
																			if (a.path) {
																				getHazardAttachment(riskAssessmentId, riskAssessmentVersionId, a.hazardId, a.path, a.isPdf)
																					.then((res: any) => {
																						setCurrentImage(res.datauri);
																						setIsAttachmentOpen(true);
																					}
																					);
																			} else {
																				setCurrentImage(a.thumbnail);
																				setIsAttachmentOpen(true);
																			}
																		}}
																	/>

																	<Button
																		className="hover-pop"
																		variant="link"
																		size="xs"
																		color="gray.500"
																		_hover={{ color: "" }}
																		onClick={() => {
																			setDeleteHazardAttachmentIsOpen({ hazardId: hazardData.rahid, attachmentPath: a.path, fileName: a.fileName, open: true });
																		}}
																	>
																		<Text textDecoration="underline">Delete</Text>
																	</Button>
																</Box>
														}
													</>
												)
											})
										}
									</Box>
								</SlideFade>
							</FormControl>
						</VStack>
					}
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							onClick={() => { setAddAttachmentModalIsOpen(false); }}
							cursor="pointer"
							size="sm"
						>
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="green"
							size="sm"
							disabled={!hazardAttachments?.some((a) => a.path === undefined)}
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => {
								setAddAttachmentModalIsOpen(false);
								updateHazardFunc();
							}}
						>
							{hazardAttachments && hazardAttachments?.length > 0 && hazardAttachments?.some((a) => a.path != undefined) ? "Update" : "Add"}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const getHazardFunc = useCallback(async () => {
		getHazard(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazard?.rahid
		)
			.then((res: any) => {

				setHazardData(res.hazard);
				setControls(res.hazard.controls || []);
				setActions(res.hazard.actions || []);

				if (res.hazard?.attachments) {

					let attachments: any = [];

					res.hazard.attachments.forEach((a: any) => {
						const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/hazardAttachment?id=${riskAssessmentId}&version=${riskAssessmentVersionId}&hazard=${a.rahahazard}&attachment=${a.rahapath}&datauri=false&thumbnail=true`;
						const attachment = {
							"thumbnail": thumbnail,
							"fileName": a.rahafilename,
							"path": a.rahapath,
							"isPdf": a.ispdf,
							"isDoc": a.isdoc,
							"hazardId": a.rahahazard,
							"versionId": a.rahaversion
						};

						attachments.push(attachment);
					});

					setHazardAttachments(attachments)
				}
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const updateHazardFunc = () => {
		updateHazard({
			id: riskAssessmentId,
			version: riskAssessmentVersionId,
			hazard: hazardData?.rahid,

			description: editingHazard?.description || hazardData?.rahdescription,
			consequences: editingHazard?.consequences || hazardData?.rahconsequences,
			peopleaffected: editingHazard?.affects || hazardData?.rahpeopleaffected,

			risklevel: editingHazard?.risk?.score !== 0 ? editingHazard?.risk?.score?.toString() : hazardData?.rahrisklevel,
			riskcategory: editingHazard?.risk?.riskText || hazardData?.rahriskcategory,
			riskcontrols: hazardData?.rahriskcontrols,

			newcontrolsrequired: "",
			newrisklevel: editingHazard?.risk?.score?.toString() || hazardData?.rahrisklevel
		})
			.then(async (res: any) => {

				let newAttachmentsToUpload: any = [];

				if (hazardAttachments.length > 0) {
					newAttachmentsToUpload = hazardAttachments?.filter((attachment: any) => {
						return !hazardData.attachments.some((a: any) => a.rahapath === attachment.path);
					});
				}

				//upload any attachments
				if (newAttachmentsToUpload.length > 0) {
					try {

						setFileUploadLoadingHazardAttachment(true);

						let updatedHazardData = null;

						for (const attachment of newAttachmentsToUpload) {

							const formData = new FormData();
							formData.append("id", riskAssessmentId);
							formData.append("version", riskAssessmentVersionId);
							formData.append("hazard", hazardData.rahid);
							formData.append("file", attachment?.file);

							const response = await addHazardAttachment(formData);

							if (response?.version?.hazards) {
								updatedHazardData = response?.version?.hazards.find((h: any) => h.rahid === hazardData.rahid);
							}
						}

						if (updatedHazardData != null) {
							setHazardData(updatedHazardData);

							let attachments: any = [];

							updatedHazardData?.attachments?.forEach((a: any) => {
								const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/hazardAttachment?id=${riskAssessmentId}&version=${riskAssessmentVersionId}&hazard=${a.rahahazard}&attachment=${a.rahapath}&datauri=false&thumbnail=true`;
								const attachment = {
									"thumbnail": thumbnail,
									"fileName": a.rahafilename,
									"path": a.rahapath,
									"isPdf": a.ispdf,
									"isDoc": a.isdoc,
									"hazardId": a.rahahazard,
									"versionId": a.rahaversion
								};

								attachments.push(attachment);
							});

							setHazardAttachments(attachments)
						}

					} catch (error) {
						console.log(error);
					} finally {
						setFileUploadLoadingHazardAttachment(false);
					}
				} else {
					setHazardData(res.hazard);
				}
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setEditingHazard({
					description: "",
					consequences: "",
					peopleaffected: "",
					risk: { riskText: "", score: 0 },
					riskcontrols: "",
					newcontrolsrequired: "",
					newrisklevel: ""
				});
				setIsEditHazardModalIsOpen(false);
			});
	};

	const deleteHazardFunc = (hazardId: any) => {
		deleteHazard(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazardId
		)
			.then((res: any) => {
				const deletedHazard = [...hazards.filter(
					(hazard: any) => hazardId === hazard.rahid
				)];

				const updatedHazards = [...hazards.filter(
					(hazard: any) => !deletedHazard.includes(hazard)
				)];

				setHazards(updatedHazards);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setDeleteHazardModalIsOpen(false);
			});
	};

	const renderImageForHazardAttachment = () => {
		return <Modal
			size="4xl"
			isOpen={isAttachmentOpen}
			onClose={() => { setIsAttachmentOpen(false) }}
		>
			<ModalOverlay />
			<ModalContent mt={0}>
				<ModalHeader />
				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>
				<ModalBody>
					<Image
						src={currentImage}
						m="5px auto 15px auto"
						p={0}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	};
	//#endregion


	//#region Controls
	const renderAddControlModal = (hazard: any) => {
		return <Modal
			size="lg"
			isOpen={isControlModalOpen}
			onClose={() => { setIsControlModalOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Add Safety Control
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<VStack
						fontSize="sm"
						alignItems="start"
						gap={2}
						w="full"
					>
						<Box
							w="full"
							p={0}
							pr={3}
							border="1px solid"
							borderColor="green.600"
							rounded="lg"
							bg="brand.500"
						>
							<HStack>
								<Image src={obbiHelper} h="100px" bg="white" roundedLeft="6.5px" />

								<Spacer />

								<Box alignItems="start" alignSelf="center">
									<Text
										fontWeight={700}
										color="white"
										mb={1}
									>
										Obbi Tips - Safety Controls
									</Text>

									<Text
										fontWeight={500}
										fontSize={{ base: "8pt", md: "xs" }}
										color="green.200"
									>
										Ensure the control effectively reduces the risk and is practical for all team members to follow.
									</Text>
								</Box>
							</HStack>
						</Box>

						<Text color="gray.600" fontWeight={500}>
							Control for <b>{hazardData?.rahdescription}</b>
						</Text>

						<FormControl isRequired isInvalid={controlError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Safety Control
							</FormLabel>

							{
								resetControlInput ? <Spinner size="sm" color="green" /> : <Textarea
									autoFocus
									rows={10}
									id="SafetyControlTextarea"
									onChange={(e: any) => {
										setNewControl(e.target.value);
									}}
								/>
							}

							{controlError && <FormErrorMessage>Please enter a control</FormErrorMessage>}
						</FormControl>
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button onClick={() => { setIsControlModalOpen(false); }} cursor="pointer" size="sm">
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="brand"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => { validateControlForm(true) }}
						>
							Add Another
						</Button>

						<Button
							className="hover-pop"
							colorScheme="brand"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => { validateControlForm() }}
						>
							Add
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const addControl = (hazard: string | number, addAnother: boolean) => {
		if (addAnother) setResetControlInput(true);

		createControl({
			id: Number(riskAssessmentId),
			version: Number(riskAssessmentVersionId),
			hazard: Number(hazard),
			description: newControl
		})
			.then((res: any) => {
				setControls([
					...controls,
					res.control
				]);
			})
			.catch((error: any) => {
				console.log(error);
			}).finally(() => {
				setNewControl("");
				setResetControlInput(false);

				if (!addAnother) setIsControlModalOpen(false);
			});
	};
	//#endregion


	//#region Actions
	const renderAddActionModal = () => {
		return <Modal
			size="lg"
			isOpen={isActionModalOpen}
			onClose={() => { setIsActionModalOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Add Action
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<VStack
						fontSize="sm"
						alignItems="start"
						gap={2}
						w="full"
					>
						<Box
							w="full"
							p={0}
							pr={3}
							border="1px solid"
							borderColor="green.600"
							rounded="lg"
							bg="brand.500"
						>
							<HStack>
								<Image src={obbiHelper} h="100px" bg="white" roundedLeft="6.5px" />

								<Spacer />

								<Box alignItems="start" alignSelf="center">
									<Text
										fontWeight={700}
										color="white"
										mb={1}
									>
										Obbi Tips - Follow up Actions
									</Text>

									<Text
										fontWeight={500}
										fontSize={{ base: "8pt", md: "xs" }}
										color="green.200"
									>
										Ensure your follow up action is clear, time-bounded, and assigns specific responsibility.
									</Text>
								</Box>
							</HStack>
						</Box>

						<Text color="gray.600" fontWeight={500}>
							Action for <b>{hazardData.rahdescription}</b>
						</Text>

						<FormControl isRequired isInvalid={followUpActionError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Follow up Action
							</FormLabel>

							<Textarea
								autoFocus
								onChange={(e: any) => {
									setNewAction({
										...newAction,
										description: e.target.value
									});
									setFollowUpActionError(false);
								}} />
							{followUpActionError && <FormErrorMessage>Please enter a follow up action</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={dateError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Due Date
							</FormLabel>

							<HStack>
								<Button
									size="sm"
									onClick={() => {
										const oneWeekFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'days': 7 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: oneWeekFromNow
										});
										setDateError(false);
									}}
								>
									1 week
								</Button>

								<Button
									size="sm"
									onClick={() => {
										const twoWeeksFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'days': 14 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: twoWeeksFromNow
										});
										setDateError(false);
									}}
								>
									2 weeks
								</Button>

								<Button
									size="sm"
									onClick={() => {
										const threeWeeksFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'days': 21 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: threeWeeksFromNow
										});
										setDateError(false);
									}}
								>
									3 weeks
								</Button>

								<Button
									size="sm"

									onClick={() => {
										const oneMonthFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'months': 1 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: oneMonthFromNow
										});
										setDateError(false);
									}}
								>
									1 month
								</Button>
							</HStack>

							<Input
								mt={2}
								type="date"
								defaultValue={newAction?.duedate}
								onChange={(e: any) => {
									setNewAction({
										...newAction,
										duedate: e.target.value
									});
									setDateError(false);
								}} />
							{dateError && <FormErrorMessage>Please enter a due date</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={assignToTeamError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Assign to Team
							</FormLabel>

							<TeamMessageCombobox
								teams={teams}
								update={selectActionTeam}
								selectedTeam={newAction?.team?.teamname}
							/>
							{assignToTeamError && <FormErrorMessage>Please select a team</FormErrorMessage>}
						</FormControl>
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button onClick={() => { setIsActionModalOpen(false); }} cursor="pointer" size="sm">
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="brand"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={validateActionForm}
						>
							Add
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const resetActionModalFields = () => {
		setNewAction({
			description: "",
			team: null,
			priority: "",
			status: "",
			category: "",
			duedate: "",
		});

		setDateError(false);
		setFollowUpActionError(false);
		setAssignToTeamError(false);
	}

	const validateEditHazardForm = () => {
		let isValid = true;

		if (isEmpty(editingHazard?.description) && isEmpty(hazardData?.rahdescription)) {
			setEditingHazardDescriptionError(true);
			isValid = false;
		}

		if (isEmpty(editingHazard?.peopleaffected) && isEmpty(hazardData?.rahpeopleaffected)) {
			setEditingHazardAffectedError(true);
			isValid = false;
		}

		if (isEmpty(editingHazard?.consequences) && isEmpty(hazardData?.rahconsequences)) {
			setEditingHazardConsequencesError(true);
			isValid = false;
		}

		if (isValid) {
			updateHazardFunc();
		}
	}

	const validateActionForm = () => {
		if (isEmpty(newAction?.description)) {
			setFollowUpActionError(true);
		}

		if (isEmpty(newAction?.duedate)) {
			setDateError(true);
		}

		if (isEmpty(newAction?.team)) {
			setAssignToTeamError(true);
		}

		if (newAction?.description && newAction?.duedate && newAction?.team) {
			addAction(hazardData.rahid);
		}
	}

	const validateControlForm = (addAnother: boolean = false) => {
		if (isEmpty(newControl)) {
			setControlError(true);
		} else {
			addControl(hazardData?.rahid, addAnother);
		}
	}

	const selectActionTeam = (arg: any): void => {
		if (arg?.teamid === "0") return;

		setNewAction({
			...newAction,
			team: arg
		})

		setAssignToTeamError(false);
	}

	const addAction = (hazard: string | number) => {
		createAction({
			id: Number(riskAssessmentId),
			version: Number(riskAssessmentVersionId),
			hazard: Number(hazard),
			description: newAction?.description,
			team: newAction?.team?.teamid,
			priority: 0, // 0 = Normal, 1 = Critical
			status: 0, // 0 = Not started, 1 = Ongoing, 2 = Blocked, 99 = Completed
			category: "Risk Assessment",
			duedate: newAction?.duedate,
		})
			.then((res: any) => {
				setActions([
					...actions,
					res.action
				]);
			})
			.catch((error: any) => {
				console.log(error);
			});

		setIsActionModalOpen(false);
		setNewAction({
			description: "",
			team: "",
			priority: "",
			status: "",
			category: "",
			duedate: "",
		});
	};
	//#endregion


	//#region Helper Functions
	const renderRiskMatrix = () => {
		const matrixData = [
			// ROW 1
			{ type: "sidebar", text: "Trivial", number: 1 },
			{ number: 1, riskText: "Trivial / Rare" },
			{ number: 2, riskText: "Trivial / Unlikely" },
			{ number: 3, riskText: "Trivial / Possible" },
			{ number: 4, riskText: "Trivial / Likely" },
			{ number: 5, riskText: "Trivial / Frequent" },

			// ROW 2
			{ type: "sidebar", text: "Minor", number: 2 },
			{ number: 2, riskText: "Minor / Rare" },
			{ number: 4, riskText: "Minor / Unlikely" },
			{ number: 6, riskText: "Minor / Possible" },
			{ number: 8, riskText: "Minor / Likely" },
			{ number: 10, riskText: "Minor / Frequent" },

			// ROW 3
			{ type: "sidebar", text: "Moderate", number: 3 },
			{ number: 3, riskText: "Moderate / Rare" },
			{ number: 6, riskText: "Moderate / Unlikely" },
			{ number: 9, riskText: "Moderate / Possible" },
			{ number: 12, riskText: "Moderate / Likely" },
			{ number: 15, riskText: "Moderate / Frequent" },

			// ROW 4
			{ type: "sidebar", text: "Serious", number: 4 },
			{ number: 4, riskText: "Serious / Rare" },
			{ number: 8, riskText: "Serious / Unlikely" },
			{ number: 12, riskText: "Serious / Possible" },
			{ number: 16, riskText: "Serious / Likely" },
			{ number: 20, riskText: "Serious / Frequent" },

			// ROW 5
			{ type: "sidebar", text: "Fatal", number: 5, icon: <Icon as={IoSkullOutline} color="gray.600" /> },
			{ number: 5, riskText: "Fatal / Rare" },
			{ number: 10, riskText: "Fatal / Unlikely" },
			{ number: 15, riskText: "Fatal / Possible" },
			{ number: 20, riskText: "Fatal / Likely" },
			{ number: 25, riskText: "Fatal / Frequent" },

			// FOOTER ROW 
			{ type: "sidebar" },
			{ type: "footer", text: "Rare", number: 1 },
			{ type: "footer", text: "Unlikely", number: 2 },
			{ type: "footer", text: "Possible", number: 3 },
			{ type: "footer", text: "Likely", number: 4 },
			{ type: "footer", text: "Frequent", number: 5 },
		];

		return <SimpleGrid columns={6}>
			{
				matrixData.map((data: any, index: any) => {
					if (data.type === "footer") {
						return <Box
							key={index}
							pt={2}
							textAlign="center"
							alignSelf="center"
						>
							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
								alignSelf="center"
								textAlign="center"
							>
								{data.text}
							</Text>

							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
							>
								{data.number && data.number}
							</Text>
						</Box>
					} else if (data.type === "sidebar") {
						return <Box
							key={index}
							textAlign="center"
							alignSelf="center"
						>
							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
							>
								{data.text} {data.icon}
							</Text>

							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
							>
								{data.number}
							</Text>
						</Box>
					} else
						return <Center
							key={index}
							cursor="pointer"
							rounded="md"
							boxShadow="sm"
							boxSize="65px"
							m="1.5px"
							px={7}
							bg={data.bgColor || getBgColor(Number(data.number))}
							_hover={{
								bg: getHoverColor(Number(data.number))
							}}
							onClick={() => {
								setEditingHazard({
									...editingHazard,
									risk: { riskText: data.riskText, score: data.number }
								});
							}}
						>
							<Text
								color="white"
								fontWeight={700}
							>
								{data.number}
							</Text>
						</Center>
				})
			}
		</SimpleGrid>
	};

	const getColor = (risk: any) => (
		risk > 0 && risk < 5 ? "#37a169" :
			risk > 4 && risk < 11 ? "#D69E2E" :
				risk > 10 && risk < 16 ? "#ED8936" :
					risk > 15 && risk < 21 ? "#E53E3E" :
						"#1A202C"
	);
	//#endregion

	useEffect(() => {
		getHazardFunc();
	}, []);

	return (
		<SlideFade in={!loading} style={{ width: "100%" }}>
			<Box w="100%">
				<Box
					w="100%"
					pt={isMobile ? 1 : 3}
					pb={isMobile ? 2 : 3}
					px={isMobile ? 3 : 5}
					bg={getColor(hazardData?.rahrisklevel)}
					color="white"
					roundedTop="lg"
					boxShadow="sm"
				>
					<Stack
						direction={{ base: "column", md: "row" }}
						mx={0}
						w="100%"
					>
						<VStack
							w="100%"
							alignItems="start"
							mt={{ base: 0, md: "7px" }}
						>
							<HStack w="100%" fontWeight={600}>
								{
									!isMobile && <Icon as={BsExclamationTriangle} fontSize="md" color="white" />
								}
								<Text color="white" fontSize={isMobile ? "sm" : "lg"}>
									{hazardData?.rahdescription}
								</Text>

								<Spacer />

								{
									isMobile && <Box>
										<HStack>
											<Button
												onClick={() => setAddAttachmentModalIsOpen(true)}
												backgroundColor="transparent"
												width={6}
												_hover={{ bg: "" }}
												_active={{ bg: "" }}
											>
												<Icon as={BsPaperclip} fontSize="xl" />
											</Button>
											<Menu>
												<MenuButton>
													<Icon
														as={BsThreeDotsVertical}
														mt={2}
														fontSize="sm"
														color="white"
														cursor="pointer"
													/>
												</MenuButton>

												<MenuList
													fontSize="sm"
													color="gray.600"
												>
													<MenuItem
														fontWeight={500}
														onClick={() => {
															setIsEditHazardModalIsOpen(hazardData);
														}}
													>
														<Icon as={BsPencil} mr={2} />
														<Text>Edit</Text>
													</MenuItem>
													<MenuItem
														fontWeight={500}
														onClick={() => {
															setAddAttachmentModalIsOpen(true);
														}}
													>
														<Icon as={BsPaperclip} mr={2} />
														<Text>
															{hazardAttachments && hazardAttachments?.length > 0 ? "Update Attachments" : "Add Attachment"}
														</Text>
													</MenuItem>

													<MenuItem
														fontWeight={500}
														onClick={() => {
															setDeleteHazardModalIsOpen(true);
														}}
													>
														<Icon as={BsTrash} mr={2} />
														<Text>Delete</Text>
													</MenuItem>
												</MenuList>
											</Menu>
										</HStack>
									</Box>
								}
							</HStack>
						</VStack>

						<Spacer />

						<HStack>
							<Box>
								<HStack gap={2}>
									<Text
										pl={{ base: 0, md: "5px" }}
										color="white"
										fontWeight={600}
										fontSize="sm"
										textAlign="center"
									>
										Risk
									</Text>

									<Button
										variant="link"
										size="xs"
										border="1px solid"
										borderColor="white"
										bg="white"
										cursor='default'
									>
										<Square
											rounded="7px"
											size="40px"
											bg={getColor(hazardData?.rahrisklevel)}
										>
											{
												(hazardData?.rahriskcategory.indexOf("Fatal") > -1) && <Icon
													as={IoSkullOutline}
													color="white"
													mr="2px"
												/>
											}

											<Text
												color="white"
												fontWeight={700}
												fontSize={(hazardData?.rahriskcategory.indexOf("Fatal") > -1) ? "10pt" : "lg"}
											>
												{hazardData?.rahrisklevel}
											</Text>
										</Square>

										<Text
											color={getColor(hazardData?.rahrisklevel)}
											fontSize="10pt"
											fontWeight={600}
											mx={3}
										>
											{hazardData?.rahriskcategory}
										</Text>
									</Button>
								</HStack>
							</Box>

							{
								!isMobile &&
								<>
									<Spacer />

									<Box>
										<HStack>
											<Button
												onClick={() => setAddAttachmentModalIsOpen(true)}
												backgroundColor="transparent"
												width={6}
												_hover={{ bg: "" }}
												_active={{ bg: "" }}
											>
												<Icon as={BsPaperclip} fontSize="xl" />
											</Button>
											<Menu>
												<MenuButton
													width={6}
													height={10}
												>
													<Icon
														as={BsThreeDotsVertical}
														fontSize="sm"
														color="white"
														cursor="pointer"
													/>
												</MenuButton>

												<MenuList
													fontSize="sm"
													color="gray.600"
												>
													<MenuItem
														fontWeight={500}
														onClick={() => {
															setIsEditHazardModalIsOpen(hazardData);
														}}
													>
														<Icon as={BsPencil} mr={2} />
														<Text>Edit</Text>
													</MenuItem>
													<MenuItem
														fontWeight={500}
														onClick={() => {
															setAddAttachmentModalIsOpen(true);
														}}
													>
														<Icon as={BsPaperclip} mr={2} />
														<Text>
															{hazardAttachments && hazardAttachments?.length > 0 ? "Update Attachments" : "Add Attachment"}
														</Text>
													</MenuItem>
													<MenuItem
														fontWeight={500}
														onClick={() => {
															setDeleteHazardModalIsOpen(true);
														}}
													>
														<Icon as={BsTrash} mr={2} />
														<Text>Delete</Text>
													</MenuItem>
												</MenuList>
											</Menu>
										</HStack>
									</Box>
								</>
							}
						</HStack>
					</Stack>
				</Box>

				{/* Controls and Actions */}
				<Box
					w="full"
					p={isMobile ? 3 : 5}
					pt={3}
					bg="white"
					roundedBottom="lg"
					boxShadow="xs"
					border="1px solid"
					borderColor="gray.100"
				>
					<Grid
						templateColumns={{ md: 'repeat(1, 1fr)', xl: 'repeat(2, 2fr)' }}
						gap={6}
					>
						<GridItem>
							<HStack mb={2}>
								<Icon as={BsPeople} color="gray.500" />
								<Text color="gray.600" fontWeight={600} fontSize={{ base: "xs", md: "sm" }}>People Affected</Text>
							</HStack>
							<Text color="gray.500" fontSize="11pt" fontWeight={500} mt={2}>
								{hazardData?.rahpeopleaffected}
							</Text>
						</GridItem>

						<GridItem>
							<HStack mb={2}>
								<Icon as={BsExclamationCircle} color="gray.500" />
								<Text color="gray.600" fontWeight={600} fontSize={{ base: "xs", md: "sm" }}>Consequences</Text>
							</HStack>
							<Text color="gray.500" fontSize="11pt" fontWeight={500} mt={2}>
								{hazardData?.rahconsequences}
							</Text>
						</GridItem>

						<Box w="full">
							<HStack mb={2}>
								<Icon as={BsLifePreserver} color="gray.500" />
								<Text
									color="gray.600"
									fontWeight={600}
									fontSize={{ base: "xs", md: "sm" }}
									mb={2}
								>
									Existing Safety Controls
								</Text>
							</HStack>

							<Box
								textAlign="center"
								border={isEmpty(controls) ? "1px solid" : "none"}
								borderColor="gray.200"
								rounded="lg"
								p={isEmpty(controls) ? 2 : 0}
								pb={isEmpty(controls) ? 3 : 0}
							>
								{
									isEmpty(controls) ?
										<VStack gap={2}>
											<Box w="full">
												<Text
													color="gray.600"
													fontSize="lg"
													fontWeight={700}
												>
													No Controls
												</Text>

												<Text
													color="gray.500"
													fontSize="sm"
													fontWeight={400}
													mt={1}
												>
													No Controls have been added to this Hazard
												</Text>
											</Box>

											<Button
												variant="outline"
												color="gray.600"
												size="sm"
												leftIcon={<Icon as={BsPlusCircle} />}
												onClick={() => {
													setIsControlModalOpen(true);
												}}
											>
												Add Safety Control
											</Button>
										</VStack> :

										<SimpleGrid columns={1} gap={2}>
											<Button
												h="full"
												minH="50px"
												fontSize="sm"
												variant="outline"
												onClick={() => {
													setIsControlModalOpen(true);
												}}
											>
												<HStack w="full">
													<Icon as={BsPlusCircle} />
													<Text>Add Safety Control</Text>
												</HStack>
											</Button>

											{
												controls?.map((c: any) => {
													return <Control
														key={c.racid}
														control={c}
														controls={controls}
														setControls={setControls}
														riskAssessmentId={riskAssessmentId}
														riskAssessmentVersionId={riskAssessmentVersionId}
														hazardId={hazardData.rahid}
														hazardData={hazardData}
														getColor={getColor}
													/>
												})
											}
										</SimpleGrid>
								}
							</Box>
						</Box>

						<Box>
							<HStack mb={2}>
								<Icon as={BsWrench} color="gray.500" />
								<Text
									color="gray.600"
									fontWeight={600}
									fontSize={{ base: "xs", md: "sm" }}
									mb={2}
								>
									Follow up Actions
								</Text>
							</HStack>

							<Box
								textAlign="center"
								border={isEmpty(actions) ? "1px solid" : "none"}
								borderColor="gray.200"
								rounded="lg"
								p={isEmpty(actions) ? 2 : 0}
								pb={isEmpty(actions) ? 3 : 0}
							>
								{
									isEmpty(actions) ? <VStack gap={2}>
										<Box w="full">
											<Text
												color="gray.600"
												fontSize={{ base: "sm", md: "lg" }}
												fontWeight={700}
											>
												No Actions
											</Text>

											<Text
												color="gray.500"
												fontSize={{ base: "xs", md: "sm" }}
												fontWeight={400}
												mt={1}
											>
												No Actions have been added to this Hazard
											</Text>
										</Box>

										<Button
											variant="outline"
											color="gray.600"
											size={isMobile ? "xs" : "sm"}
											leftIcon={<Icon as={BsPlusCircle} />}
											onClick={() => {
												setIsActionModalOpen(true);
											}}
										>
											Add Action
										</Button>
									</VStack> :

										<SimpleGrid columns={1} gap={2}>
											<Button
												h="full"
												minH="50px"
												fontSize="sm"
												variant="outline"
												onClick={() => {
													resetActionModalFields();
													setIsActionModalOpen(true);
												}}
											>
												<HStack w="full">
													<Icon as={BsPlusCircle} />
													<Text>Add Action</Text>
												</HStack>
											</Button>

											{
												actions?.map((a: any) => {
													return <Action
														key={a.raaid}
														action={a}
														actions={actions}
														setActions={setActions}
														riskAssessmentId={riskAssessmentId}
														riskAssessmentVersionId={riskAssessmentVersionId}
														hazardId={hazardData.rahid}
													/>
												})
											}
										</SimpleGrid>
								}
							</Box>
						</Box>
					</Grid>
					<Box w="100%" mt={8} mb={3}>
						{
							hazardData?.attachments?.length > 0 &&
							<VStack alignItems="start">
								<FormControl>
									<SlideFade in={!loading} offsetY="20px">
										<FormLabel
											fontWeight={600}
											color="gray.600"
											mb={2}
										>
											<HStack>
												<Icon
													as={BsPaperclip}
													fontSize="lg"
													color="gray.500"
												/>
												<Text>Hazard Attachments</Text>
											</HStack>
										</FormLabel>

										<Box
											display={'flex'}
											flexDirection={'row'}
											flexWrap={'wrap'}
											justifyContent={'flex-start'}
											alignItems={'center'}
											gap={3}
										>
											{
												hazardData?.attachments.map((a: any, index: any) => {
													const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/hazardAttachment?id=${riskAssessmentId}&version=${riskAssessmentVersionId}&hazard=${a.rahahazard}&attachment=${a.rahapath}&datauri=false&thumbnail=true`;
													return (
														<Box key={index}>
															<Image
																className="hover-pop"
																cursor="pointer"
																src={thumbnail}
																fallbackSrc='https://via.placeholder.com/160'
																alt={a.rahafilename}
																boxSize='160px'
																boxShadow="xs"
																objectFit="cover"
																border="1px solid"
																borderColor="gray.200"
																rounded="lg"
																onClick={() => {
																	getHazardAttachment(riskAssessmentId, riskAssessmentVersionId, a.rahahazard, a.rahapath, (a.ispdf || a.isdoc))
																		.then((res: any) => {
																			if (a.ispdf || a.isdoc) {
																				setCurrentCert({
																					id: Number(a.rahahazard),
																					isPdf: a.ispdf,
																					fileName: a.rahafilename,
																					uri: res.datauri
																				});
																				setPdfIsOpen(true);
																			} else {
																				setCurrentImage(res.datauri);
																				setIsAttachmentOpen(true);
																			}
																		}
																		);
																}}
															/>

															<Button
																className="hover-pop"
																variant="link"
																size="xs"
																color="gray.500"
																_hover={{ color: "" }}
																onClick={() => {
																	setDeleteHazardAttachmentIsOpen({ hazardId: a.rahahazard, attachmentPath: a.rahapath, fileName: a.rahafilename, open: true });
																}}
															>
																<Text textDecoration="underline">Delete</Text>
															</Button>
														</Box>
													)
												})
											}
										</Box>
									</SlideFade>
								</FormControl>
							</VStack>
						}
					</Box>

				</Box>

				{renderEditHazardModal()}
				{renderDeleteHazardModal()}
				{renderAddControlModal(hazard)}
				{renderAddActionModal()}
				{renderImageForHazardAttachment()}
				{renderDeleteHazardAttachmentModal()}
				{renderPdf()}
				{renderAddHazardAttachmentModal()}
			</Box>
		</SlideFade>
	);
};